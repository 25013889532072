import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import VueYoutube from 'vue-youtube'
import VueParticles from 'vue-particles'
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import Scrollspy from 'vue2-scrollspy';
import VueGtag from "vue-gtag";
var VueScrollTo = require('vue-scrollto');

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(VueParticles)
Vue.use(SweetModal)
Vue.use(Scrollspy);
Vue.use(VueScrollTo)
Vue.use(BootstrapVue)


Vue.use(VueGtag, {
  config: { id: "G-FN6ENCHYC4" }
}, router);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
