import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index9.vue')
  },
  {
    path: "/giris-yap",
    name: "Login page",
    component: () => import('../views/login.vue')
  },
  {
    path: "/kayit-ol",
    name: "Signup page",
    component: () => import('../views/signup.vue')
  },
  {
    path: '/sifremi-unuttum',
    name: 'Forgot Password page',
    component: () => import('../views/forgot-password.vue')
  },
  {
    path: '/mulakat-simulasyonu',
    name: 'interview simulation',
    component: () => import('../views/interview-simulation.vue')
  },
  {
    path: '/ne-yapiyoruz',
    name: 'what we do',
    component: () => import('../views/what-we-do.vue')
  },
  {
    path: '/cozum-ortaklarimiz',
    name: 'partners',
    component: () => import('../views/partners.vue')
  },
  {
    path: '/iletisim',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/sss',
    name: 'faq',
    component: () => import('../views/faq.vue')
  },
  {
    path: '/hakkimizda',
    name: 'about',
    component: () => import('../views/about-us.vue')
  },
  {
    path: '/kariyer',
    name: 'career',
    component: () => import('../views/career.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/yorumlar',
    name: 'partners',
    component: () => import('../views/comments.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to){
    if(to.hash){
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

export default router
